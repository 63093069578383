
import {defineComponent, ref, computed} from 'vue';
import {useI18n} from 'vue-i18n';
import {useStore} from 'vuex';
import {injectOrThrow} from "@/helpers/inject";
import {Labels, TypeLabels} from "@/models";
import {useForm, useField} from 'vee-validate';
import {ucFirst} from '@/helpers/firstRegister';

export default defineComponent({
	emits: ["dialog-closed", "item-added"],
	props: {
		open: {
			type: Boolean,
			default: false
		},
		id_tag: {
			type: Number,
			required: true
		}
	},
	setup(props, {emit}) {
		const { t } = useI18n();
		const store = useStore();

		const {handleSubmit, handleReset, values} = useForm();

		const { value: selectedTypeLabel, errorMessage: typeLabelsError } = useField<TypeLabels.TypeLabel>('selectedTypeLabel', 'required');
		const typeLabels = computed(() => store.state.dictionaries.typeLabels as TypeLabels.TypeLabel[]);
		function selectTypeLabel(selected: TypeLabels.TypeLabel) {
			selectedTypeLabel.value = selected ?? {};
		}

		const { value: label, errorMessage: labelError } = useField<string>('label', 'required');

		const isLoading = ref(false);
		const service = injectOrThrow<Labels.LabelService>('Labels.LabelService');

		const add = handleSubmit(async () => {
			isLoading.value = true;

			try {
				await service.add({
					label: values.label,
					id_type_label: selectedTypeLabel.value.id,
					id_tag: props.id_tag
				});

				store.commit('notification/successMessage', {title: t("form.notify.success.added", { name: ucFirst(t("dictionary.label")) })});
				emit("item-added");
				closeModal();
			} catch (e) {
				return;
			} finally {
				isLoading.value = false;
			}
		});

		const closeModal = () => {
			emit('dialog-closed');
			handleReset();
		}

		return {
			t,
			typeLabels, selectedTypeLabel, typeLabelsError, selectTypeLabel,
			label, labelError,
			isLoading, add, closeModal
		}
	}
})
