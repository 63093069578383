
import {defineComponent, ref, computed, watch, PropType} from 'vue';
import {useI18n} from 'vue-i18n';
import {useStore} from 'vuex';
import {injectOrThrow} from "@/helpers/inject";
import {Labels, TypeLabels} from "@/models";
import {useForm, useField} from 'vee-validate';
import {ucFirst} from '@/helpers/firstRegister';

export default defineComponent({
	emits: ["dialog-closed", "item-changed"],
	props: {
		open: {
			type: Boolean,
			default: false
		},
		id_tag: {
			type: Number,
			required: true
		},
		item: {
			type: Object as PropType<Labels.Label>,
			required: true
		}
	},
	setup(props, {emit}) {
		const { t } = useI18n();
		const store = useStore();

		const {handleSubmit, handleReset, setValues, values} = useForm();

		const { value: selectedTypeLabel, errorMessage: typeLabelsError } = useField<TypeLabels.TypeLabel>('selectedTypeLabel', 'required');
		const typeLabels = computed(() => store.state.dictionaries.typeLabels as TypeLabels.TypeLabel[]);
		function selectTypeLabel(selected: TypeLabels.TypeLabel) {
			selectedTypeLabel.value = selected ?? {};
		}

		const { value: label, errorMessage: labelError } = useField<string>('label', 'required');

		const set = () => {
			setValues({
				label: props.item.label || ""
			});

			if(props.item.id_type_label) {
				const findTypeLabel = typeLabels.value.find(typeLabel => typeLabel.id === props.item.id_type_label);

				if(findTypeLabel)
					selectedTypeLabel.value = findTypeLabel;
			}
		}

		set();

		watch(() => props.open, () => set());

		const isLoading = ref(false);
		const service = injectOrThrow<Labels.LabelService>('Labels.LabelService');

		const edit = handleSubmit(async () => {
			isLoading.value = true;

			try {
				await service.edit({
					id: props.item.id,
					label: values.label,
					id_type_label: selectedTypeLabel.value.id,
					id_tag: props.id_tag
				});

				store.commit('notification/successMessage', {title: t("form.notify.success.edited", { name: ucFirst(t("dictionary.label")) })});
				emit("item-changed");
				closeModal();
			} catch (e) {
				return;
			} finally {
				isLoading.value = false;
			}
		});

		const closeModal = async () => {
			emit('dialog-closed');
			handleReset();
		};

		return {
			t,
			typeLabels, selectedTypeLabel, typeLabelsError, selectTypeLabel,
			label, labelError,
			isLoading, edit, closeModal
		}
	}
})
