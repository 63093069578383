
import {defineComponent, computed, PropType, ref} from 'vue';
import {useI18n} from 'vue-i18n';
import DataTable from '@/components/tables/DataTable.vue';
import DataTableHeader from '@/components/tables/DataTableHeader.vue';
import {Tags, TypeTags} from "@/models";
import {useStore} from 'vuex';
import {injectOrThrow} from "@/helpers/inject";
import {ucFirst} from '@/helpers/firstRegister';
import Labels from '@/views/labels/Listing.vue';

export default defineComponent({
	emits: ["row-clicked", "page-changed", "item-removed", "list-updated"],
	components: {
		DataTable,
		DataTableHeader,
		Labels
	},
	props: {
		list: {
			type: Object as PropType<Tags.TagList>,
			default: null
		},
		loading: {
			type: Boolean,
			default: false
		}
	},
	setup(props, {emit}) {
		const { t } = useI18n();
		const store = useStore();
		const itemActiveId = ref(null as number|null);

		const headers = computed(() => [
			{ text: t("dictionary.typeTag"), value: 'type_tag_name' },
			{ text: t("dictionary.name"), value: 'comment' },
			{ text: t("dictionary.status"), value: 'date_time_end', status: {itemActiveId: itemActiveId.value, action: (item: Tags.Tag) => item.date_time_end ? unblock(item.id) : block(item.id)}, width: "1%" },
			{ classCell: 'px-0', icon: {name: 'basket', title: `${t("button.remove")} ${t("dictionary.tag")}`, action: (item: Tags.Tag) => emit("item-removed", item)}, width: "1%" },
			{ classCell: 'pl-0', isExpanded: true, width: "1%" },
		])

		const typeTags = computed(() => store.state.dictionaries.typeTags as TypeTags.TypeTag[]);

		const items = computed(() => props.list.items.map(item => {
			const findTypeTag = (id: number) => typeTags.value.find((typeTag: TypeTags.TypeTag) => typeTag.id === id);

			return {
				...item,
				type_tag_name: findTypeTag(item.id_type_tag)?.name
			}
		}));

		const service = injectOrThrow<Tags.TagService>('Tags.TagService');

		const block = async (id: number) => {
			itemActiveId.value = id;

			try {
				await service.block(id);

				store.commit('notification/successMessage', {title: t("form.notify.success.blocked", { name: ucFirst(t("dictionary.tag")) })});
				emit("list-updated");
			} catch (e) {
				return;
			} finally {
				itemActiveId.value = null;
			}
		};

		const unblock = async (id: number) => {
			itemActiveId.value = id;

			try {
				await service.unblock(id);

				store.commit('notification/successMessage', {title: t("form.notify.success.unblocked", { name: ucFirst(t("dictionary.tag")) })});
				emit("list-updated");
			} catch (e) {
				return;
			} finally {
				itemActiveId.value = null;
			}
		};

		return { headers, items }
	}
})
