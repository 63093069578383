
import {defineComponent, ref, computed} from 'vue';
import {useI18n} from 'vue-i18n';
import {useStore} from 'vuex';
import {injectOrThrow} from "@/helpers/inject";
import {Tags, TypeTags} from "@/models";
import {useForm, useField} from 'vee-validate';
import {ucFirst} from '@/helpers/firstRegister';

export default defineComponent({
	emits: ["dialog-closed", "item-added"],
	props: {
		open: {
			type: Boolean,
			default: false
		}
	},
	setup(_, {emit}) {
		const { t } = useI18n();
		const store = useStore();

		const {handleSubmit, handleReset, values} = useForm();

		const { value: selectedTypeTag, errorMessage: typeTagsError } = useField<TypeTags.TypeTag>('selectedTypeTag', 'required');
		const typeTags = computed(() => store.state.dictionaries.typeTags as TypeTags.TypeTag[]);
		function selectTypeTag(selected: TypeTags.TypeTag) {
			selectedTypeTag.value = selected ?? {};
		}

		const { value: comment, errorMessage: commentError } = useField<string>('comment', 'required');

		const isLoading = ref(false);
		const service = injectOrThrow<Tags.TagService>('Tags.TagService');

		const add = handleSubmit(async () => {
			isLoading.value = true;

			try {
				await service.add({
					id_type_tag: selectedTypeTag.value.id,
					comment: values.comment,
					id_enterprise: store.state.globals.enterpriseId
				});

				store.commit('notification/successMessage', {title: t("form.notify.success.added", { name: ucFirst(t("dictionary.tag")) })});
				emit("item-added");
				closeModal();
			} catch (e) {
				return;
			} finally {
				isLoading.value = false;
			}
		});

		const closeModal = () => {
			emit('dialog-closed');
			handleReset();
		}

		return {
			t,
			typeTags, selectedTypeTag, typeTagsError, selectTypeTag,
			comment, commentError,
			isLoading, add, closeModal
		}
	}
})
