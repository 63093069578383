import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_data_table_header = _resolveComponent("data-table-header")!
  const _component_data_table = _resolveComponent("data-table", true)!

  return (_openBlock(), _createBlock(_component_data_table, {
    headers: _ctx.headers,
    items: _ctx.items,
    isAction: true,
    onRowClicked: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('row-clicked', $event))),
    loading: _ctx.loading,
    pagination: _ctx.list,
    onPageChanged: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit("page-changed", $event)))
  }, {
    headers: _withCtx(({ headers }) => [
      _createVNode(_component_data_table_header, { headers: headers }, null, 8, ["headers"])
    ]),
    expanded: _withCtx(({ item }) => [
      _createElementVNode("div", null, _toDisplayString(item), 1)
    ]),
    _: 1
  }, 8, ["headers", "items", "loading", "pagination"]))
}