import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createSlots as _createSlots, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "mb-4" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_app_icon = _resolveComponent("app-icon")!
  const _component_app_text_field = _resolveComponent("app-text-field")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_app_text_field, {
      name: "search",
      modelValue: _ctx.search,
      "onUpdate:modelValue": [
        _cache[0] || (_cache[0] = ($event: any) => ((_ctx.search) = $event)),
        _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit("search-changed", _ctx.search)))
      ],
      label: _ctx.t("dictionary.search"),
      placeholder: _ctx.t("form.placeholder.findTag"),
      outlined: "",
      iconAppend: "",
      iconAppendAction: () => { _ctx.search = ""; _ctx.$emit("search-changed", _ctx.search) }
    }, _createSlots({ _: 2 }, [
      (_ctx.search.length)
        ? {
            name: "iconAppend",
            fn: _withCtx(() => [
              _createVNode(_component_app_icon, {
                icon: "cross",
                size: 14,
                class: "text-red"
              })
            ]),
            key: "0"
          }
        : undefined
    ]), 1032, ["modelValue", "label", "placeholder", "iconAppendAction"])
  ]))
}