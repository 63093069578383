
import {defineComponent, ref} from 'vue';
import {useI18n} from 'vue-i18n';

export default defineComponent({
	emits: ['search-changed'],
	setup() {
		const { t } = useI18n();

		const search = ref("");

		return { t, search }
	}
});
