
import {defineComponent, computed, PropType} from 'vue';
import {useI18n} from 'vue-i18n';
import DataTable from '@/components/tables/DataTable.vue';
import DataTableHeader from '@/components/tables/DataTableHeader.vue';
import {Labels, TypeLabels} from "@/models";
import {useStore} from 'vuex';

export default defineComponent({
	emits: ["row-clicked", "page-changed", "item-removed"],
	components: {
		DataTable,
		DataTableHeader
	},
	props: {
		list: {
			type: Object as PropType<Labels.LabelList>,
			default: null
		},
		loading: {
			type: Boolean,
			default: false
		}
	},
	setup(props, {emit}) {
		const { t } = useI18n();
		const store = useStore();

		const headers = computed(() => [
			{ text: t("dictionary.label"), value: 'label' },
			{ text: t("dictionary.typeLabel"), value: 'type_label_name' },
			{ text: t("dictionary.comment"), value: 'type_label_comment' },
			{ classCell: 'pl-0', icon: {name: 'basket', title: `${t("button.remove")} ${t("dictionary.label")}`, action: (item: Labels.Label) => emit("item-removed", item)}, width: "1%" },
		])

		const typeLabels = computed(() => store.state.dictionaries.typeLabels as TypeLabels.TypeLabel[]);

		const items = computed(() => props.list.items.map(item => {
			const findTypeLabel = (id: number) => typeLabels.value.find((typeLabel: TypeLabels.TypeLabel) => typeLabel.id === id);

			return {
				...item,
				type_label_name: findTypeLabel(item.id_type_label)?.name,
				type_label_comment: findTypeLabel(item.id_type_label)?.comment
			}
		}));

		return { headers, items }
	}
})
