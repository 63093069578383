
import {defineComponent, ref, onMounted} from 'vue';
import {useI18n} from 'vue-i18n';
import {List} from "@/components/tables/DataTable";
import DataTable from "./components/listing/DataTable.vue";
import {injectOrThrow} from "@/helpers/inject";
import {Labels} from "@/models";
import Add from "./Add.vue";
import Edit from "./Edit.vue";
import Remove from "@/views/components/dialogs/Remove.vue";
import {useStore} from "vuex";
import {setCookie} from '@/helpers/cookie';

export default defineComponent({
	props: {
		id_tag: {
			type: Number,
			required: true
		}
	},
	components: {
		DataTable,
		Add,
		Edit,
		Remove
	},
	setup(props) {
		const { t } = useI18n();
		const { state, commit } = useStore();

		const changePage = (pagination: List) => {
			load({
				pageNumber: pagination.current,
				pageSize: pagination.size
			})

			commit('globals/setPageSize', pagination.size);
			setCookie("pageSize", pagination.size);
		};

		const list = ref(new Labels.LabelList({ size: state.globals.pageSize }));
		const query = ref<Labels.LabelQueryParams>({ pageSize: state.globals.pageSize });
		const service = injectOrThrow<Labels.LabelService>('Labels.LabelService');

		const loading = ref(true);
		const load = async (params: Labels.LabelQueryParams) => {
			loading.value = true;
			query.value = { ...query.value, ...params };
			list.value = await service.list(query.value, props.id_tag);
			loading.value = false;
		}

		onMounted(async () => await load(query.value));

		const isAddOpened = ref(false);

		const item = ref({} as Labels.Label);

		const isEditOpened = ref(false);
		const edit = (value: Labels.Label) => {
			item.value = value;
			isEditOpened.value = true;
		};

		const isRemoveOpened = ref(false);
		const remove = (value: Labels.Label) => {
			item.value = value;
			isRemoveOpened.value = true;
		};

		return {
			t, service,
			loading, load, changePage,
			list, item,
			isAddOpened,
			isEditOpened, edit,
			isRemoveOpened, remove
		}
	}
})
