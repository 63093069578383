
import {defineComponent, ref, onMounted, watch} from 'vue';
import {useI18n} from 'vue-i18n';
import {useStore} from 'vuex';
import {List} from "@/components/tables/DataTable";
import DataTable from "./components/listing/DataTable.vue";
import {injectOrThrow} from "@/helpers/inject";
import {Tags, Searches} from "@/models";
import {useRouter, useRoute} from "vue-router";
import Add from "./Add.vue";
import Edit from "./Edit.vue";
import Remove from "@/views/components/dialogs/Remove.vue";
import {setCookie} from '@/helpers/cookie';
import Search from "./components/listing/Search.vue";
import axios from "axios";

export default defineComponent({
	components: {
		DataTable,
		Add,
		Edit,
		Remove,
		Search
	},
	setup() {
		const { t } = useI18n();
		const route = useRoute();
		const router = useRouter();
		const { state, commit, dispatch } = useStore();

		const changePage = (pagination: List) => {
			load({
				pageNumber: pagination.current,
				pageSize: pagination.size
			})

			commit('globals/setPageSize', pagination.size);
			setCookie("pageSize", pagination.size);
		};

		const list = ref(route.name === 'searches'
			? new Searches.SearchList({ size: state.globals.pageSize })
			: new Tags.TagList({ size: state.globals.pageSize })
		);
		const query = ref<Tags.TagQueryParams>({ pageSize: state.globals.pageSize });
		const service = injectOrThrow<Tags.TagService>('Tags.TagService');
		const searchService = injectOrThrow<Searches.SearchService>('Searches.SearchService');

		const isCancelRequest = ref(false);

		const loading = ref(true);
		const load = async (params: Tags.TagQueryParams) => {
			const axiosSource = axios.CancelToken.source();

			if (isCancelRequest.value) axiosSource.cancel();
			isCancelRequest.value = true;

			if(state.globals.enterpriseId) {
				loading.value = true;
				query.value = { ...query.value, ...params, ...route.query };
				list.value = route.name === 'searches'
					? await searchService.search(query.value)
					: await service.list(query.value, state.globals.enterpriseId, axiosSource.token).then(data => {
						isCancelRequest.value = false;
						return data;
					}).catch(err => {
						if (axios.isCancel(err)) {
							isCancelRequest.value = false;
							return {
								total: 0,
								size: 0,
								current: 1,
								last: 1,
								items: []
							};
						}
					}) ?? {
						total: 0,
						size: 0,
						current: 1,
						last: 1,
						items: []
					}
				loading.value = false;
			} else {
				router.push({name: "change_enterprise"})
			}
		}

		onMounted(async () => {
			await dispatch('dictionaries/getTypeTags');
			await load(query.value);
			await dispatch('dictionaries/getTypeLabels');
		});

		watch(() => route.query, () => {
			if(Object.keys(route.query).length)
				load(query.value)
		});

		const isAddOpened = ref(false);

		const item = ref({} as Tags.Tag);

		const isEditOpened = ref(false);
		const edit = (value: Tags.Tag) => {
			item.value = value;
			isEditOpened.value = true;
		};

		const isRemoveOpened = ref(false);
		const remove = (value: Tags.Tag) => {
			item.value = value;
			isRemoveOpened.value = true;
		};

		const isSearchOpened = ref(false);

		return {
			t, service,
			loading, load, changePage,
			list, item,
			isAddOpened,
			isEditOpened, edit,
			isRemoveOpened, remove,
			isSearchOpened
		}
	}
})
